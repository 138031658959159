import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page3 } from './data';
import FAQ from '../assets/imgs/FAQ.png';

// import { HashRouter as Router, Link } from "react-router-dom";

export default function Page3(props) {
  const renderDesc = (descriptions) => {
    const descHtml= descriptions.map((item, i) => (
      <p key={i.toString()}>
        {item}
      </p>
    ));
    return descHtml;
  };
  const children = page3.map((item, i) => (
    <div className={['one-card'].join(' ')} key={i.toString()}>
      <div className={['common-card', i === 2 ? 'common-card1' : 'common-card2'].join(' ')} >
        <p className="title">{item.title}</p>
        <div className="detail">{renderDesc(item.descriptions)}</div>
      </div>
      <div className="one-img">
        <img src={props.isMobile ? item.mobImg : item.img} className={i === 2 ? "cardImg" : null} alt="" />
      </div>
    </div>
  ));

  return (
    <section id="FAQ" className={'block3'+(props.isMobile? '-mobile': '')}>
      <div>
            <div className={['topTip'].join(' ')}>
                {/* <h2>FAQ</h2> */}
                <img className='faq' src={FAQ} alt='FAQ'/>
                <div>El equipo de CashLana ha compilado una lista de dudas comunes que esperan que le ayuden.</div>
                {/* <div></div> */}
            </div>
            <QueueAnim
                type="bottom"
                className="page"
                key="page3"
                component={Row}
              >
              {children}
            </QueueAnim>
          </div>
      {/* {
        !props.isMobile ? (
          <div>
            <div className={['topTip', props.isMobile && 'toopTip-mobile'].join(' ')}>
            <h2>FAQ</h2>
                <div>El equipo de CashLana ha compilado una lista de dudas comunes que esperan que le ayuden.</div>
            </div>
            <QueueAnim
                type="bottom"
                className="page"
                key="page3"
                component={Row}
              >
              {children}
            </QueueAnim>
          </div>
        ) : (
          <>
            <div className="topTip">
              <h2>CARACTERíSTICAS</h2>
              <div>Nuestro objetivo es facilitar los préstamos, </div>
              <div>ayudar a más usuarios necesitados y construir la relación.</div>
          </div>
          <div className="page3-mobile-content">
            {
              page3 && page3.map((item, idx) => {
                if (idx >= 3) {
                  return null
                }
                return  <div key={idx} className="one-card">
                <img src={item.img} alt="" />
                <div className="common-card">
                  <p className="card-title">{item.title}</p>
                  <div className="card-detail">{renderDesc(item.descriptions)}</div>
                </div>
              </div>
              })
            }
            <div className="mobile-common-footer">
              <p className="title">Regulaciones</p>
              <div className="list-content regulation">
              <Router>
                <Link to="/privacyAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <span>《Acuerdo de Privacidad》</span>
                </Link>
                <Link to="/serviceAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <span>《Acuerdo de Servicio》</span>
                </Link>
              </Router>
              </div>
            </div>
          </div>
          </>
        )
      } */}

    </section>
  );
}
