import React, { Component } from 'react';
import Home from './Home';
import PrivacyAgreement from './Page/PrivacyAgreement';
import ServiceAgreement from './Page/ServiceAgreement';
import { HashRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
        <Route exact path="/serviceAgreement" component={ServiceAgreement} />
      </Router>
    );
  }
}

export default App;
